import React from 'react';

import '../CSS/carousel.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import mgm from '../companies/mgm.jpg'
import chikn from '../companies/chikn.jpg';
import elysium from '../companies/elysium.jpg';
import splendid from '../companies/splendidGroup.JPG';
import mama from '../companies/mamaShelter.png';
import casino from '../companies/casino.png';
import jks from '../companies/jks.png';
import coolInc from '../companies/coolinc.svg';
import shiuli from '../companies/shiuli.jpg';
import enoki from '../companies/enoki.jpg';
import chourangi from '../companies/chourangi.png'
import palihill from '../companies/palihill.png'


export default function CarouselCompanies() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1
        }
    };
    return (
        <>
            < Carousel
                arrows={false}
                infinite
                responsive={responsive}
                autoPlay
            // autoPlaySpeed={2000} 

            >
                <img className="companyImage mgm"
                    src={mgm} />
                <img className="companyImage"
                    src={chikn} />
                <img className="companyImage"
                    src="https://www.thembsgroup.co.uk/wp-content/uploads/2020/05/Dishoom-logo-final.png" />
                <img className="companyImage"
                    src="https://pbs.twimg.com/profile_images/1349694631060103169/PiD5Kwj8_400x400.jpg" />
                <img className="companyImage"
                    src={elysium} />
                {/* <img className="companyImage"
                src="https://scontent-lhr8-1.xx.fbcdn.net/v/t1.6435-9/68779187_108665260499792_5130226950605897728_n.png?_nc_cat=109&ccb=1-3&_nc_sid=973b4a&_nc_ohc=nvU-YbNLFCcAX_kqTKs&_nc_ht=scontent-lhr8-1.xx&oh=b8f451532f7acfcfa638e655490c3992&oe=612B50C0" /> */}
                <img className="companyImage"
                    src={splendid} />
                <img className="companyImage"
                    src={casino} />
            </Carousel>
            < Carousel
                arrows={false}
                infinite
                responsive={responsive}
                autoPlay
            // autoPlaySpeed={2000} 

            >
                <img className="companyImage"
                    src={jks} />
                <img className="companyImage"
                    src={coolInc} />
                <img className="companyImage"
                    src={mama} />
                <img className="companyImage"
                    src={shiuli} />
                <img className="companyImage"
                    src={enoki} />
                <img className="companyImage"
                    src={chourangi} />
                <img className="companyImage"
                    src={palihill} />
            </Carousel>
        </>
    );
}

