import { Navbar, Nav, Form, button } from 'react-bootstrap';
import lkhLogo from '../logo.svg' ;
import '../CSS/nav.css';

function Navigation() {
    return (
        <Navbar bg="dark" variant="dark" sticky="top" expand="sm">
            <img src={lkhLogo} className="logo"/>
            <Navbar.Brand href="/">Little Kitchen Help LTD</Navbar.Brand>


            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav>
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/privacy">Privacy Policy</Nav.Link>

                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )
}
export default Navigation;