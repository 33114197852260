import logo from './logo.svg';
import './CSS/nav.css';
import './CSS/App.css';
import Navigation from './Components/Nav';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPage from './Components/PrivacyPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



function App() {
  return (
    <div className="App">

      <header className="App-header">
        <Navigation />

      </header>
      <body>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/privacy">
              <PrivacyPage />
            </Route>
          </Switch>
        </Router>
      </body>

    </div>
  );
}

export default App;
