import React from 'react';
import restaurant from '../../icons/restaurant.svg'
import catering from '../../icons/catering.svg'
import hotel from '../../icons/hotel.svg'
import hr from '../../icons/hr.svg'
import other from '../../icons/other.svg'
import './style.css';
import filter from '../../filter.svg'



export default function Services() {
    return (
        <div className={"container"}>
            <h2 className="servicesTitle">Areas we provide candidates for</h2>
            <div className="row">
                <div className="cell">
                    <img className="icon" src={restaurant} />
                    <h3 className="name">Restaurants, Pubs</h3>
                </div>
                <div className="cell">
                    <img className="icon" src={catering} />
                    <h3 className="name">Catering</h3>
                </div>
            </div>
            <div className="row">
                <div className="cell">
                    <img className="icon" src={hotel} />
                    <h3 className="name">Hotel Operations</h3>
                </div>
                <div className="cell">
                    <img className="icon" src={hr} />
                    <h3 className="name">Human Resources</h3>
                </div>
            </div>
            <div className="row">
                <div className="cell">
                    <img className="icon" src={other} />
                    <h3 className="name">Other areas</h3>
                </div>
            </div>
            <div className="recruitmentProcessContainer">
                <h2 className="servicesTitle">Recruitment Process</h2>
                <div className="recruitmentProcessBody">
                    <h6 className="recruitmentProcessBodyText">
                        The interview identifies their skills, aspirations and their experience.
                        Making sure that each candidate you receive has the core competancies you require.
                        <br />
                        <br />
                        We have worked with  clients from many sectors allowing us to give you
                        a tailored service that you will not find elsewhere.
                    </h6>
                    <img className="recruitmentProcessImage" src={filter} />
                </div>
            </div>
        </div>
    )
}