import { Navbar, Nav, Form, button } from 'react-bootstrap';
import ProductImg from '../product.svg';
import Connect from '../connect.svg';
import Contact from '../contact.svg';
import React, { useState, useEffect } from 'react';
import { send } from 'emailjs-com';
import HeroVideo from '../hero.mp4';
import CarouselCompanies from './CarouselCompanies';
import Services from './Services/Services';
export default function Home() {





    const [email, setEmail] = useState("");
    const [who, setWho] = useState("Company");
    const [body, setBody] = useState("");
    // const [file, setFile] = useState("");

    const [submitSuccess, setSubmitSucess] = useState(false);
    const [submitFailure, setSubmitFailure] = useState(false);

    const [isInvalidEmail, setInvalidEmail] = useState(false);
    const [isInvalidBody, setInvalidBody] = useState(false);

    useEffect(() => {
        //
    }, [isInvalidEmail, isInvalidBody])



    const sendEmail = () => {

        if (email.indexOf("@") === -1) {
            setInvalidEmail(true);
            return;
        }
        setInvalidEmail(false);
        if (body.length == 0) {
            setInvalidBody(true);
            return;
        }
        setInvalidBody(false);

        // console.log("email=", email);
        // console.log("who=", who);
        // console.log("body=", body);
        // console.log("file=", file);

        const toSend = {
            from_name: email,
            to_name: 'littlekitchenhelp@outlook.com',
            message: body,
            reply_to: '',
        }




        send(
            'service_9350oal',
            'template_3kfyi96',
            toSend,
            'user_2iEzoC2OxbEFRwHlGaPr4'
        )
            .then((response) => {
                setSubmitSucess(true);
                setSubmitFailure(false);
                console.log('SUCCESS!', response.status, response.text);
                console.log('toSend was!', toSend);
            })
            .catch((err) => {
                setSubmitSucess(false);
                setSubmitFailure(true);
                console.log('FAILED...', err);
            });
    }

    return (
        <div className="home">
            <div className="videoSection">
                <video
                    autoPlay
                    loop
                    muted
                    className="video"
                >
                    <source src={HeroVideo} type="video/mp4" />
                </video>
            </div>
            <div className="homeTitleBox">
                <h1 className="homeMain heroText">Little Kitchen Help</h1>
                <h2 className="homesub">Here To Help</h2>
            </div>
            {/* <img src={ProductImg} alt="ProductImg Logo" className={"homeHero"} /> */}
            <div className="about">
                <h1 className="aboutMain">About us</h1>
                <br />
                <div className="textImageDivider">
                    <img src={Connect} alt="ProductImg Logo" className={"aboutHero"} />
                    <div className="aboutTitleBox">
                        <h3 className="homesub aboutText">Little Kitchen Help Ltd provides outstanding services for all your recruitment needs. We manage your recruitment process which allows you to focus on your business. We are proud of our responsiveness and provide all our clients with a single point of contact.</h3>
                    </div>
                    <Services />
                </div>
            </div>
            <div className="companiesSection">

                <h4 className="companiesTitle" >Partners We Have Worked With</h4>
                <CarouselCompanies />
            </div>
            <div className="about form">
                <h1 className="aboutMain">Contact us</h1>
                <div className="textImageDivider">

                    <div className="aboutTitleBox aboutSection">
                        <div className="formClass">
                            <Form>
                                {isInvalidEmail && <Form.Label className={"invalid"}>Email address is not valid</Form.Label>}
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control onChange={chosenValue => setEmail(chosenValue.target.value)} type="email" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Who you are</Form.Label>
                                    <Form.Control onChange={chosenValue => setWho(chosenValue.target.value)} as="select">
                                        <option>Company</option>
                                        <option>Candidate</option>
                                    </Form.Control>
                                </Form.Group>
                                {isInvalidBody && <Form.Label className={"invalid"}>Please specify the reason for contacting</Form.Label>}
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Reason for contact:</label>
                                    <textarea onChange={chosenValue => setBody(chosenValue.target.value)} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                {/* <div class="form-group">
                                    <label for="exampleFormControlFile1">(Optional) Relevant File Upload</label>
                                    <div className="fileUploadContainer">
                                        <input onChange={chosenValue => setFile(chosenValue.target.files[0])} type="file" class="form-control-file" id="exampleFormControlFile1" />
                                    </div>
                                </div> */}

                                <p>By submitting you agree to our <a href="/privacy">Privacy Policy</a></p>
                                <button onClick={() => sendEmail()} type="button" class="btn btn-primary">Submit</button>
                                {submitFailure && <Form.Label className={"submitMessage invalid"}>Something went wrong. Please reload the page and try again.</Form.Label>}
                                {submitSuccess && <Form.Label className={"submitMessage valid"}>Submitted Successfully</Form.Label>}
                            </Form>


                        </div>
                    </div>

                    <img src={Contact} alt="ProductImg Logo" className="aboutHero contactHero" />


                </div>


            </div>
        </div>
    )
}